import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter,Route} from 'react-router-dom';
import { ThemeProvider,CSSReset } from '@chakra-ui/core'

import './asset/sass/style.scss';
import Loading from "./components/Loading";

const Home = lazy(() => import('./pages/Home') );

const About = lazy(() => import('./pages/About') );
const Contact = lazy(() => import('./pages/Contact') );
const Form = lazy(() => import('./pages/Form') );
const Pages = lazy(() => import('./pages/Pages') );
const NotFound = lazy(()=> import('./components/NotFound'));

const App = () => {
    return <ThemeProvider>
        <BrowserRouter>
            <CSSReset/>
            <Suspense fallback={<Loading/>}>
                <Route exact path="/" component={Home}/>
                <Route path="/contact" component={Contact}/>

                <Route path="/page/:name?" component={Pages}/>
                <Route exact path="/form/:name?" component={Form}/>
                <Route exact path={"/404"} component={NotFound}/>
                <Route exact path={"*"} to={"/404"}/>
            </Suspense>
        </BrowserRouter>
    </ThemeProvider>
}

ReactDOM.render(<App/>,document.getElementById('app'));
