import React from 'react';
import {Flex, Heading, Spinner} from "@chakra-ui/core";

const Loading = (props) => {
    return <Flex justifyContent={"center"} alignItems={"center"} className={"obat-loading"} flexDirection={"column"}>
        <Spinner
            thickness="2px"
            speed="0.65s"
            size="xl"
        />
        <Heading as={"h3"} size={"sm"}>Loading...</Heading>
    </Flex>
}

export default Loading
